var desktopBgs = false;
var isLoading = false;
var animating = false;


function changeBgs() {
    if ($(window).width() > 940 && !desktopBgs) {
        $(".multiple-bg").each(function () {
            var bg = $(this).attr("data-desktop-bg");
            $(this).css("background-image", "url(" + bg + ")");
        });

        desktopBgs = true;
    } else if ($(window).width() < 940 && desktopBgs) {
        $(".multiple-bg").each(function () {
            var bg = $(this).attr("data-mobile-bg");
            $(this).css("background-image", "url(" + bg + ")");
        });

        desktopBgs = false;
    }
}


function thumbStripWidths() {
    if ($("html").hasClass("overthrow-enabled")) {
        $("#gallery-content .thumbnail-strip").each(function () {
            var totalWidth = (($(this).find("img").length * ($(this).find("img:first").width() + 23)) - 15);
            $(this).find("ul").width(totalWidth);
        });
    }
}


jQuery(document).ready(function ($) {

    changeBgs();
    thumbStripWidths();

    FastClick.attach(document.body);

    $("#mobile-menu-button").bind("click", function () {
        if ($(this).hasClass("close")) {
            $(this).removeClass("close");
            $("#wrapper").removeClass("menu-open");

            setTimeout(function () {
                $("#mobile-menu").hide();
            }, 300);
        } else {
            $(this).addClass("close");
            $("#wrapper").addClass("menu-open");
            $("#mobile-menu").show();
        }
    });


    $("#main-menu-container li.menu-item-has-children > a").bind("click", function (e) {
        e.preventDefault();
        $(this).focus();
    });


    if ($("body").hasClass("page-template-careers-php")) {
        $('.flexslider').flexslider({
            "animation": "slide",
            "slideshowSpeed": 6000,
            "animationSpeed": 800,
            "controlNav": false
        });
    }


    $(window).resize(function () {
        changeBgs();
        thumbStripWidths();
    });


    if ($("#featured-images").length > 0) {
        var currentImage;
        var lastImage = $("#featured-images .main-col").attr("data-last-image-index");


        var newIndex;

        setInterval(function () {
            currentImage = $("#featured-images .main-col img.active").attr("data-image-index");

            do {
                newIndex = getRand(lastImage);
            } while (newIndex == currentImage);


            $("#featured-images .main-col img[data-image-index='" + newIndex + "']").addClass("show");

            setTimeout(function () {
                $("#featured-images .main-col img.active").removeClass("active");
                $("#featured-images .main-col img.show").removeClass("show").addClass("active");
            }, 1500);

        }, 5000);

    }


    $("#gallery-content .thumbnail-strip .thumbnail a").bind("click", function (e) {
        e.preventDefault();

        if (isLoading || $(e.target).parent().parent().hasClass("active")) {
            return;
        }


        $(".thumbnail-strip .active").removeClass("active");
        $(e.target).parent().parent().addClass("active");

        isLoading = true;
        var imgID = $(e.target).parent().attr("data-image-id");

        // Fix height of viewer
        var currentHeight = $("#viewer").height();
        $("#viewer").height(currentHeight);

        // Scroll to it and clear the contents
        // $.scrollTo( ($("#viewer").position().top - 15) , 300, { axis: "y" });

        $("#gallery-content #viewer img, #gallery-content #viewer div").addClass("hide");


        setTimeout(function () {

            $("#gallery-content #viewer").empty();

            $.get(BASEURL + "?view=" + imgID + "&ajax=true")
                .done(function (data) {
                    var result = JSON.parse(data);

                    if (result.status == "success") {
                        $("#gallery-content #viewer").append(
                            $("<a>", {"href": result.galleryItem.url, "target": "_blank", "title": "View full photo"})
                                .append(
                                    $("<img>", {
                                        "class": "hide",
                                        "src": result.galleryItem.sizes["gallery-full"],
                                        "alt": "Full sized image: " + result.galleryItem.alt
                                    })
                                )
                        );

                        imagesLoaded(document.querySelector("#viewer img"), function () {
                            $("#viewer img").removeClass("hide");
                            $("#viewer").height("auto");
                        });
                    } else {

                    }

                    isLoading = false;
                })
                .fail(function () {
                    $("#viewer").height("auto");
                    isLoading = false;
                });

        }, 500);
    });


    $("#gallery-content .thumbnail-strip a.scroll-right, #gallery-content .thumbnail-strip a.scroll-left").bind("click", function (e) {
        e.preventDefault();

        if (animating) {
            return;
        }

        var container = $(this).parent().find(".inner").width();
        var thumbnailStrip = $(this).parent().find("ul");
        var offset = parseInt(thumbnailStrip.css("left"));
        var imageWidth = thumbnailStrip.find("img:first").width();

        if ($(e.target).hasClass("scroll-right")) {
            var newOffset = (offset - (imageWidth + 23));
        } else {
            var newOffset = (offset + (imageWidth + 23));
        }


        var max = (thumbnailStrip.width() - container);

        if (newOffset <= 0 && newOffset >= -(max + imageWidth)) {
            animating = true;

            thumbnailStrip.animate({
                "left": newOffset
            }, 200, function () {
                animating = false;
            });
        }
    });


    $("#contact-form .tabs a").bind("click", function (e) {
        e.preventDefault();

        if (!$(this).parent().hasClass("active")) {
            var area = $(this).attr("rel");
            var areaName = $(this).text();
            var mapSrc = $(this).attr("data-map-src");

            showArea($(this), area, areaName, mapSrc);
        }
    });


    var contactPref = decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent("contactPref").replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1"));

    if (contactPref) {
        var tab = $("#contact-form .tabs a[rel='" + contactPref + "']");
        var areaName = tab.text();
        var mapSrc = tab.attr("data-map-src");

        showArea(tab, contactPref, areaName, mapSrc);
    }


    $(".post-type-archive-collateral .media-post .download-button").bind("click", function (e) {
        e.preventDefault();
        $(".item-download-form:visible").hide();
        $(this).parent().parent().find(".item-download-form").slideDown(300);
    });

});


function showArea(tab, area, areaName, mapSrc) {
    $("#contact-form .tabs .active").removeClass("active");
    $(tab).parent().addClass("active");

    $(".info.active").removeClass("active");
    $(".info[rel='" + area + "']").addClass("active");

    $("#contact-form .form-wrapper h2").text("Contact " + areaName);

    $("#map").attr("src", mapSrc);

    $("#txt_office").val(area);
    $("#input_5_11").val(areaName);

    document.cookie = "contactPref=" + area + "; path=/";
}


function getRand(last) {
    return Math.floor(Math.random() * last);
}

function ctaBannerMove() {

    var ctaBanners = document.querySelectorAll('.cta--banner');

    for (var i = ctaBanners.length - 1; i >= 0; i--) {

        var ctaBanner = ctaBanners[i];
        var ctaParent = ctaBanner.closest('section:not(.cta--banner)');

        console.log(ctaParent);

        // ctaParent.parentNode.appendChild(ctaBanner);

        insertAfter(ctaBanner, ctaParent);

    }

}

window.addEventListener('load', (event) => ctaBannerMove());

function insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

document.addEventListener('DOMContentLoaded', () => {
    const videoWrappers = document.querySelectorAll('.video-wrapper');

    if (videoWrappers.length > 0) {
        videoWrappers.forEach(videoWrapper => {
            const posterImage = videoWrapper.querySelector('.iframe-poster');
            const iframe = videoWrapper.querySelector('iframe');

            if (posterImage && iframe) {
                posterImage.addEventListener('click', () => {
                    videoWrapper.classList.add('playing');
                });

                iframe.addEventListener('click', () => {
                    videoWrapper.classList.remove('playing');
                });
            }
        });
    }
});

